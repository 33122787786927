function getItemFromListContainsDisplay(lst: any[], val: string, idKey: string = '_id', displayName: string = 'name'): any {
    if (lst) {
        for (let itm of lst) {
            if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
                return itm[displayName];
            }
        }
    }
    return '';
}
export function prepareCase(itm, metaFieldSetting = undefined) {
    if (itm.geolocation && itm.geolocation !== '') {
        let lngLat = JSON.parse(itm.geolocation);
        if (lngLat.lat && lngLat.lng) {
            itm['lat'] = lngLat.lat;
            itm['lng'] = lngLat.lng;
        }
    }
    if (['inactive', 'completed'].includes(itm.status)) { // inactive and complete
        itm['icon'] = '/assets/images/icon/case-sign-inactive.png';
    } else {
        itm['icon'] = '/assets/images/icon/case-sign.png';
    }
    itm['markerType'] = 'case';
    if (metaFieldSetting) {
        itm['type'] = getItemFromListContainsDisplay(metaFieldSetting['case_type'].enum, itm['case_type'], 'value', 'displayName');
    }
    return itm;
}
export function prepareCamera(itm, metaFieldSetting = undefined, useSelected: boolean = false) {
    if (itm.geolocation && itm.geolocation !== '') {
        let lngLat = JSON.parse(itm.geolocation);
        if (lngLat.lat && lngLat.lng) {
            itm['lat'] = lngLat.lat;
            itm['lng'] = lngLat.lng;
        }
    }
    // let randomNumbers = this.getRandomElement(this.baseImageSetDegrees);
    // itm['icon'] = this.baseImageSet[randomNumbers];
    if (useSelected) {
        if (itm.selected) {
            itm['icon'] = '/assets/images/icon/video-cam.png';
            itm['labelColor'] = 'red';
        } else {
            itm['icon'] = '/assets/images/icon/video-cam-red.png';
            itm['labelColor'] = 'black';
        }
    } else {
        itm['icon'] = '/assets/images/icon/video-cam.png';
    }
    itm['markerType'] = 'camera';
    return itm;
}
export function prepareInvestigator(itm, metaFieldSetting = undefined, useSelected: boolean = false, useIcon: boolean = false) {
    if (itm.geolocation && itm.geolocation !== '') {
        let lngLat = JSON.parse(itm.geolocation);
        if (lngLat.lat && lngLat.lng) {
            itm['lat'] = lngLat.lat;
            itm['lng'] = lngLat.lng;
        }
    }
    itm['label'] = itm['alternative_name'] ? itm['alternative_name'] : itm['name'];
    if (useSelected) {
        if (itm.selected) {
            itm['icon'] = '/assets/images/icon/police-pin-blue.png';
            itm['labelColor'] = '#7030A0';
        } else {
            itm['icon'] = '/assets/images/icon/police-pin-black.png';
        }
    } else {
        if (itm.picture_icon && !useIcon) {
            // itm.scale = true;
            itm['icon'] = itm.picture_icon;
            // itm.icon = '/assets/images/pin.svg'
        }
        else
            itm['icon'] = '/assets/images/icon/police-pin-black.png';
        itm['labelColor'] = '#7030A0';
    }
    itm['picture'] = itm['pictureLink'];
    itm['rank'] = itm['rankName'] ? itm['rankName'] : itm['rank'];
    itm['years_in_service'] = itm['years_in_service'];
    itm['markerType'] = 'investigator';
    return itm;
}
export function prepareGroupInvestigator(itm, metaFieldSetting = undefined, useSelected: boolean = false, useIcon: boolean = false) {
    if (itm.geolocation && itm.geolocation !== '') {
        let lngLat = JSON.parse(itm.geolocation);
        if (lngLat.lat && lngLat.lng) {
            itm['lat'] = lngLat.lat;
            itm['lng'] = lngLat.lng;
        }
    }
    itm['label'] = itm['name'];
    if (useSelected) {
        if (itm.selected) {
            itm['icon'] = '/assets/images/icon/group-default.png';
            itm['labelColor'] = '#255290';
        } else {
            itm['icon'] = '/assets/images/icon/group.png';
        }
    } else {
        if (itm.picture_icon && !useIcon) {
            itm['icon'] = itm.picture_icon;
        }
        else
            itm['icon'] = '/assets/images/icon/group-default.png';
        itm['labelColor'] = '#255290';
    }
    itm['markerType'] = 'investigator-group';
    return itm;
}
export function prepareAnnotation(itm, metaFieldSetting = undefined) {
    if (itm.geolocation && itm.geolocation !== '') {
        let lngLat = JSON.parse(itm.geolocation);
        if (lngLat.lat && lngLat.lng) {
            itm['lat'] = lngLat.lat;
            itm['lng'] = lngLat.lng;
        }
    }
    if (itm.document && itm.document.url && itm.document.url !== '') {
        itm['picture'] = itm.document.url;
    }
    itm['label'] = itm['name'];
    itm['icon'] = '/assets/images/icon/annotation.png';
    itm['markerType'] = 'annotation';


    if (itm.car_of_interest && itm.car_color && itm.car_color != '') {
        itm['icon'] = '/assets/images/icon/car_' + itm.car_color + '.png';
    }
    return itm;
}

export function prepareNote(itm, metaFieldSetting = undefined) {
    if (itm.geolocation && itm.geolocation !== '') {
        let lngLat = JSON.parse(itm.geolocation);
        if (lngLat.lat && lngLat.lng) {
            itm['lat'] = lngLat.lat;
            itm['lng'] = lngLat.lng;
        }
    }
    if (itm.document && itm.document.url && itm.document.url !== '') {
        itm['picture'] = itm.document.url;
    }
    itm['label'] = itm['name'];

    switch (itm.type) {
        case 'annotation':
            itm['icon'] = '/assets/images/icon/annotation.png';
            itm['markerType'] = 'annotation';
            break;
        case 'location':
            itm['icon'] = '/assets/images/icon/location.png';
            itm['markerType'] = 'location';
            break;
        case 'note':
            itm['icon'] = '/assets/images/icon/note.png';
            itm['markerType'] = 'note';
            break;
        default:
            if (itm.document) {
                switch (itm.document.type) {
                    case 'video':
                        itm['icon'] = '/assets/images/icon/video.png';
                        itm['markerType'] = 'video';
                        break;
                    case 'audio':
                        itm['icon'] = '/assets/images/icon/audio.png';
                        itm['markerType'] = 'audio';
                        break;
                    case 'file':
                        itm['icon'] = '/assets/images/icon/file.png';
                        itm['markerType'] = 'file';
                        break;
                    case 'image':
                        itm['icon'] = '/assets/images/icon/image.png';
                        itm['markerType'] = 'image';
                        break;
                }
            }
            break;
    }

    if (itm.car_of_interest) {
        if (itm.car_color && itm.car_color != '')
            itm['icon'] = '/assets/images/icon/car_' + itm.car_color + '.png';
        else {
            itm['icon'] = '/assets/images/icon/car_blue.png';
        }
    }
    else if (itm.person_of_interest) {
        if (itm.person_of_interest_gender == 'female')
            itm['icon'] = '/assets/images/icon/person-blue-female.png';
        else
            itm['icon'] = '/assets/images/icon/person-blue-male.png';
    }
    // console.log(itm);
    return itm;
}