import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, ElementRef, TemplateRef, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LayoutUtilsService, LoaderService, RequestService, StoreService } from '../../../shared/services';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { ModalLanguageDialogComponent } from '../custom-language-dialog/custom-language-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { EnumPipe } from '../../pipes';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public selectedUser: any;
  public languageSelected: string = 'en';
  public title = environment.serverName;
  public selectedOrganization: string = '';
  public userType: string = 'default';
  public dataType: string = 'organization';
  public dataTypeDisplay: string = 'Organization';
  public hasNew: boolean = true; //TODO change this
  public pageSize = 10;
  public date: Date = new Date();
  public screenWidth: number;
  public pictureLink: string = 'assets/images/profile.png';
  public isDnd: boolean = true;

  @Input() headerTemplate: TemplateRef<any>;

  constructor(private requestService: RequestService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private firebaseHandler: FirebaseHandlersService, private notificationService: NotificationService, private enumPipe: EnumPipe,
    private loaderService: LoaderService) {
    if (localStorage.getItem('languageSelected')) {
      this.languageSelected = localStorage.getItem('languageSelected');
    }
    this.userType = this.requestService.getUserType();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.pictureLink = data.pictureLink;
        }
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      minWidth: '40vw',
      width: 'fit-content',
      data: {
        dataType: 'user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: this.selectedUser,
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }

  public goHome() {
    this.router.navigate(['/']);
  }

  logout() {
    // this.loaderService.display(true);
    // this.adAuthService.logoutPopup().subscribe(() => {
    // Perform any cleanup or redirect to the login page
    // localStorage.removeItem('test_azure_data');
    // localStorage.removeItem('test_azure_token');
    console.log('User logged out');
    this.firebaseHandler.logout();
    // });
  }
}
