<div class="loading-screen-wrapper" *ngIf="showLoader">
  <div class="loading-screen-icon">
    <div id="splash-screen mb-0" class="splash-screen">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" overflow="visible"
        fill="#385a80" stroke="#385a80">
        <defs>
          <rect id="loader" x="46.5" y="40" width="7" height="20" rx="2" ry="2" transform="translate(0 -30)" />
        </defs>
        <use xlink:href="#loader" transform="rotate(120 50 50)">
          <animate attributeName="opacity" values="0;1;0" dur="1s" begin="0.33s" repeatCount="indefinite"></animate>
        </use>
        <use xlink:href="#loader" transform="rotate(240 50 50)">
          <animate attributeName="opacity" values="0;1;0" dur="1s" begin="0.67s" repeatCount="indefinite"></animate>
        </use>
        <use xlink:href="#loader" transform="rotate(360 50 50)">
          <animate attributeName="opacity" values="0;1;0" dur="1s" begin="1.00s" repeatCount="indefinite"></animate>
        </use>
      </svg>
      <span class="title"> Premier Group Insurance </span>
    </div>
  </div>
</div>