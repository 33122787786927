import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import moment from 'moment';

class My2ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-datetime',
  templateUrl: './custom-datetime.component.html',
  styleUrls: ['./custom-datetime.component.scss']
})
export class CustomDateTimeComponent implements OnInit {
  public esMatcher = new My2ErrorStateMatcher();
  public dateValue: string = moment(new Date().toISOString()).format('YYYY-MM-DD');
  public timeValue: string = moment(new Date().toISOString()).format('HH:mm');
  @Input() metadata: any = undefined;
  @Input() disabled: boolean = false;
  @Input() disabledDate: boolean = false;
  @Input() hideDate: boolean = false;
  @Input() disabledTime: boolean = false;
  public _value: string = '';
  @Input()
  set value(value: string) {
    this._value = value;
    if (value) {
      let momentValue = moment(value);
      this.dateValue = momentValue.format('YYYY-MM-DD');
      this.timeValue = momentValue.format('HH:mm');
    }
  }
  get value(): string {
    return this._value;
  }

  @Output() dateChange = new EventEmitter<string>();
  @ViewChild('dataForm') form: NgForm;
  constructor() {
    // if(this.form)
    //  this.form.onSubmit(undefined);
  }
  ngOnInit() {
  }
  setupData() {

  }
  public setDateAttribute(val) {
    let selectedDate = new Date(val);

    // Get the year from the selected date
    let year = selectedDate.getFullYear();
    // Check if the year has more than 4 digits
    if (year.toString().length > 4) {
      this.dateValue = '';
    } else {
      this.dateChange.emit(moment(val + 'T' + this.timeValue).format('YYYY-MM-DDTHH:mm'));
    }
  }
  public setTimeAttribute(val) {
    if (this.dateValue && (this.dateValue === 'Invalid date' || this.dateValue === '')) {
      this.dateValue = moment(new Date().toISOString()).format('YYYY-MM-DD');
    }
    if (val && val !== '') {
      this.dateChange.emit(moment(this.dateValue + 'T' + val).format('YYYY-MM-DDTHH:mm'));
    } else {
      this.dateChange.emit(moment(this.dateValue + 'T00:00').format('YYYY-MM-DDTHH:mm'));
    }
  }
  // this.returnMarginValue.emit(this.marginValue);
  public onSubmit() {
    // do nothing
  }
}
