<form #personForm="ngForm" *ngIf="data" class="customDialog">
	<div class="col-xl-12" *ngIf="data">
		<h1 mat-dialog-title>{{data.title}}</h1>
		<div mat-dialog-content>
			<div *ngIf="data.modalSetting">
				<mat-card *ngIf="data.modalSetting.fields">
					<!-- <mat-card-header>
						<mat-card-title>{{data.type}}</mat-card-title>
					</mat-card-header> -->
					<mat-card-content>
						<div class="form-group">
							<div *ngFor="let col of data.modalSetting.fields;let mainIndex = index" class="fieldItem">
								<div
									*ngIf="col.type !== 'action' && (((col.editable || !col.generated) && col.creatable) || col.control|| (col.editable && action == 'edit'))">
									<!-- <label class="control-label" *ngIf="col.name">{{col.displayName}}<span *ngIf="col.required || !col.generated"> *</span>: </label> -->
									<span class="fieldInput">
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'string'"
											class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<input matInput [autofocus]="col.name===focusField"
												maxlength="{{col.charlimit ? col.charlimit : 1000}}"
												[required]="!col.nullable" name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled"
												(keydown.enter)="searchAddress($event, col['name'])">

											<mat-icon *ngIf="col['name'] === 'address' || col['name'] === 'address_jp'"
												matSuffix style="cursor: pointer;"
												(click)="searchAddress(undefined,col['name'])">travel_explore</mat-icon>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</mat-form-field>
										<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'number'
											&& ((data.dataType !== 'room') || ((data.dataType === 'room' && col.name !== 'order')))" class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<input *ngIf="col.positive" matInput
												maxlength="{{col.charlimit ? col.charlimit : 1000}}" type="number"
												[autofocus]="col.name===taretFocusField" [required]="!col.nullable"
												name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												pattern="{{col.integer? '[0-9]+' : '' }}"
												step="{{col.integer? 1 : '' }}"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" min="0">
											<input *ngIf="!col.positive" matInput
												maxlength="{{col.charlimit ? col.charlimit : 1000}}" type="number"
												[autofocus]="col.name===taretFocusField" [required]="!col.nullable"
												name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												pattern="{{col.integer? '[-]?[0-9]+' : '' }}"
												step="{{col.integer? 1 : '' }}"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-icon matSuffix *ngIf="col.info && col.info !== ''" class="infoCss"
												matTooltip="{{col.info | translate}}">info</mat-icon>
											<!-- <mat-error
												*ngIf="col.integer && personForm && personForm.controls && personForm.controls[col.name] && personForm.controls[col.name].hasError('pattern')">
												Should be Integer
											</mat-error> -->
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</mat-form-field>
										<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'number'
											&& (data.dataType === 'room' && col.name === 'order' && data.data['type'] && data.data['type'] === '3')"
											class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<mat-select name="{{col.name}}" [required]="!col.nullable"
												[autofocus]="col.name===taretFocusField"
												(selectionChange)="setAttribute(col.name, $event.value)"
												[(ngModel)]="data.data[col.name]">
												<!-- <mat-option value="" >Select {{col.displayName | translate}}</mat-option> -->
												<mat-option *ngFor="let itm of orderOfExhibit" [value]="itm">{{itm}}
												</mat-option>
											</mat-select>
											<mat-icon matSuffix *ngIf="col.info && col.info !== ''" class="infoCss"
												matTooltip="{{col.info | translate}}">info</mat-icon>
											<mat-icon matSuffix *ngIf="data.data[col.name]"
												(click)="clearSimpleValue($event, col.name)" class="cancelIcon"
												[class.cancelIconWithInfo]="col.info && col.info !== ''"
												matTooltip="{{'Clear' | translate}}" matTooltipClass="tooltip-red">close
											</mat-icon>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'email'"
											class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<input matInput type="email" id="{{col.name}}" [required]="!col.nullable"
												[autofocus]="col.name===taretFocusField" name="{{col.name}}"
												ng-model="{{col.name}}" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												(input)="validateEmail(col.name, $event)"
												[errorStateMatcher]="esMatcher" [disabled]="data.data._id && col.unique"
												pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
											<mat-hint align="end">{{'Email format' | translate}}</mat-hint>
											<mat-error
												*ngIf="personForm && personForm.controls && personForm.controls[col.name] && personForm.controls[col.name].hasError('pattern') && fieldsObject && fieldsObject[col.name] && fieldsObject[col.name]['pattern'] && fieldsObject[col.name]['pattern'] !== ''">
												Invalid email format.
											</mat-error>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'url'"
											class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<input matInput type="text" [required]="!col.nullable" name="{{col.name}}"
												[autofocus]="col.name===taretFocusField" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher"
												pattern="^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$"
												[disabled]="col.disabled || (data.data._id && col.unique)">
											<mat-hint align="end">{{'URL format' | translate}}</mat-hint>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'phone'"
											class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<input matInput type="phone" [autofocus]="col.name===taretFocusField"
												maxlength="{{col.charlimit ? col.charlimit : 1000}}"
												[required]="!col.nullable" name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'color' && !col.preColors"
											class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<input matInput type="color" [required]="!col.nullable" name="{{col.name}}"
												[autofocus]="col.name===taretFocusField" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
											<button mat-icon-button matSuffix
												*ngIf="data.data[col.name] && data.data[col.name] !== '' && !col.disabled"
												(click)="data.data[col.name] = ''">
												<mat-icon style="font-size: 30px;">format_color_reset</mat-icon>
											</button>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'color' && col.preColors"
											class="inputClass">
											<mat-label>{{col.name | translate}}</mat-label>
											<div class="preColorBox"
												*ngIf="data.data[col.name] !== '' && col.preColors.includes(data.data[col.name])"
												[ngStyle]="{'background-color': data.data[col.name] ? data.data[col.name] : ''}">
											</div>
											<div class="preSelectColorBox">
												<mat-select name="{{col.name}}"
													(selectionChange)="data.data[col.name] = $event.value"
													[autofocus]="col.name===taretFocusField"
													[value]="data.data[col.name]">
													<mat-option value="">
														{{'No Color' | translate}}
													</mat-option>
													<mat-option *ngFor="let pg of col.preColors" value="{{pg}}">
														<div class="colorBox" [ngStyle]="{'background-color': pg}">
														</div>
													</mat-option>
												</mat-select>
											</div>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</mat-form-field>
										<mat-form-field *ngIf="col.type === 'password'" class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<input matInput type="password" maxlength="64" minlength="8"
												[autofocus]="col.name===taretFocusField" [required]="!col.nullable"
												name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
											<mat-error
												*ngIf="data.data[col.name] && (data.data[col.name].length < 8 || data.data[col.name].length > 64)">
												{{col['displayName'] | translate}} should be of at least 8 characters
												and maximum 64 characters.
											</mat-error>
										</mat-form-field>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'textarea'">
											<mat-form-field
												*ngIf="!col.charlimit || (col.charlimit && col.charlimit > 0)"
												hintLabel="{{'Max X Characters' | translate: { limit: col.charlimit ? col.charlimit : 1000} }}">
												<mat-label>{{col.displayName | translate}}</mat-label>
												<textarea matInput placeholder="{{col['displayName'] | translate}}"
													name="{{col['name']}}" [required]="!col['nullable']"
													[autofocus]="col.name===taretFocusField"
													maxlength="{{col.charlimit ? col.charlimit : 1000}}"
													[(ngModel)]="data.data[col.name]" ng-model="col['name']"
													cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
													[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
													</textarea>
												<mat-hint align="end">
													{{data.data[col.name]?.length || 0}}/{{col.charlimit ? col.charlimit
													: 1000}}
													<mat-icon *ngIf="col.info && col.info !== ''" class="infoCss"
														matTooltip="{{col.info | translate}}">info</mat-icon>
												</mat-hint>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col['displayName'] | translate}} {{'is required' | translate}}
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="col.charlimit && col.charlimit === -1">
												<mat-label>{{col.displayName | translate}}</mat-label>
												<textarea matInput placeholder="{{col['displayName'] | translate}}"
													name="{{col['name']}}" [required]="!col['nullable']"
													[autofocus]="col.name===taretFocusField"
													[(ngModel)]="data.data[col.name]" ng-model="col['name']"
													cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
													[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
													</textarea>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col['displayName'] | translate}} {{'is required' | translate}}
												</mat-error>
											</mat-form-field>
											<!-- <mat-form-field hintLabel="Max 10 characters" appearance="fill">
    <mat-label>Enter some input</mat-label>
    <input matInput #input maxlength="10" placeholder="Ex. Nougat">
    <mat-hint align="end">{{input.value?.length || 0}}/10</mat-hint>
  </mat-form-field> -->
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'boolean' && (col.name !== 'privacyterm' && col.name !== 'isSuperAdmin' && col.name !== 'allday')"
											style="margin-bottom:15px;">
											<mat-checkbox class="example-margin" name="{{col.name}}"
												[(ngModel)]="data.data[col.name]"
												[disabled]="col.disabled || (col.name === 'isPasswordRequired' && data.data['isSuperAdmin']) || (data.action && data.action === 'create-room-lobby' && (col.name === 'main' || col.name === 'emptyRoom' || col.name === 'showbg')) || (data.dataType === 'session' && data.data['isKeepActive'] && col.name === 'autostart') || (data.dataType === 'session' && (data.data['isKeepActive'] || data.data['streamMode'] === 'hls') && col.name === 'autoend' ) || (data.dataType === 'session' && data.data['streamMode'] === 'hls' && col.name === 'isKeepActive')"
												(change)="setBooleanAttribute(col.name, $event.checked)"
												matTooltip="{{col.info | translate}}">{{col.displayName}} <mat-icon
													*ngIf="col.info && col.info !== ''" class="infoCss"
													matTooltip="{{col.info | translate}}">info</mat-icon>
											</mat-checkbox>
											<mat-icon matSuffix
												*ngIf="col.name === 'isRecurrence' && data.data['isRecurrence']"
												class="recurrenceCss" matTooltip="{{'Edit Recurrence' | translate}}"
												(click)="editRecurrence()">event_repeat</mat-icon>
											<span *ngIf="col.name === 'isRecurrence' && data.data['isRecurrence']"
												style="margin-left: 10px;color: rgba(0,0,0,.54);">
												{{ruleText}}
											</span>
											<!-- <mat-radio-group [value]="data.data[col.name]" name="{{col.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
									        <mat-radio-button class="example-margin" value="false">False</mat-radio-button>
									        <mat-radio-button class="example-margin" value="true">True</mat-radio-button>
									      </mat-radio-group> -->
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'boolean' && col.name === 'isSuperAdmin' && col.name !== 'allday' && (data.data['resources'] && data.data['resources'].length > 0 && data.data['resources'][0]['_id'] === adminId)"
											style="margin-bottom:15px;">
											<mat-checkbox class="example-margin" name="{{col.name}}"
												[(ngModel)]="data.data[col.name]"
												(change)="setSuperAdminAttribute( $event.checked)"
												matTooltip="{{col.info | translate}}" [disabled]="col.disabled">
												{{col.displayName}} <mat-icon *ngIf="col.info && col.info !== ''"
													class="infoCss"
													matTooltip="{{col.info | translate}}">info</mat-icon>
											</mat-checkbox>
											<!-- <mat-radio-group [value]="data.data[col.name]" name="{{col.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
									        <mat-radio-button class="example-margin" value="false">False</mat-radio-button>
									        <mat-radio-button class="example-margin" value="true">True</mat-radio-button>
									      </mat-radio-group> -->
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'boolean' && col.name === 'allday'">
											<app-custom-startenddatetime
												[value]="{allday: data.data['allday'], startdate: data.data['startdate'], enddate: data.data['enddate']}"
												[metadata]="{'allday': metaFieldSetting['allday'], 'startdate': metaFieldSetting['startdate'], 'enddate': metaFieldSetting['enddate']}"
												(dataChange)="setStartEndDateTimeAttribute($event)">
											</app-custom-startenddatetime>
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'tags'">
											<app-custom-tags #customTag [parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name]" [required]="!col.nullable"
												[showReqHint]="true" [placeholder]="col.displayName" [canAdd]="true"
												[dataTypeDisplay]="col.displayName"
												(onSelectReturn)="setAttribute(col.name, $event)" [itemName]="col.name"
												[parentFormSubmitted]="isSubmitted" [canDelete]="true"
												[dataTypeParent]="data.dataType" [tagType]="col.tagtype"
												[dataTypeParentDisplay]="data.dataType">
											</app-custom-tags>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'reference'"
											style="margin-bottom: 20px;">
											<app-custom-select *ngIf="col.control && col.reference.kind === 'single'"
												[parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]['_id']"
												[itemName]="col.name" [required]="!col.nullable"
												[dataType]="col.reference.to" [apiTarget]="col.reference.api"
												[filters]="col.filters" [placeholder]="col.displayName"
												(onSelectReturn)="setReferenceControllerAttribute(col.name, '_id', $event)"
												[conditionalDisable]="col.disabled"></app-custom-select>
											<app-custom-select
												*ngIf="!col.control && data.data[col.name] && col.reference.kind === 'single'"
												[parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]['_id']"
												[itemName]="col.name" [required]="!col.nullable"
												[dataType]="col.reference.to" [filters]="col.filters"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"
												[conditionalDisable]="col.disabled"></app-custom-select>
											<app-custom-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name === 'resources'"
												[parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name][0]['_id']" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)"
												[filters]="col.filters" [parentData]="data.data"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"
												[disabled]="col.disabled"></app-custom-select>
											<app-custom-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name === 'role'"
												[parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name][0]['_id']" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleAttribute(col.name, $event)"
												[filters]="col.filters"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"
												[conditionalDisable]="col.disabled"></app-custom-select>
											<app-custom-multiple-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name !== 'resources' && col.name !== 'role'"
												[value]="data.data[col.name]" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												[filters]="col.filters"
												(onSelectReturn)="setMultipleReferenceAttribute(col.name, $event)"
												[isSubmitted]="isSubmitted" [customError]="customError[col.name]"
												[conditionalDisable]="col.disabled">
											</app-custom-multiple-select>
											<!-- <div
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name !== 'resources' && col.name !== 'role'">
												{{col.name}}: {{col.disabled}}:{{col.filters | json}}</div> -->
										</div>
										<div *ngIf="data.dataType === 'event' &&  (col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'objectjson' && col.name === 'attendees'"
											style="display: inline-block;" style="width:100%">
											<mat-form-field>
												<mat-label>{{col.displayName}}</mat-label>
												<mat-select multiple name="attendees" [required]="!col.nullable"
													(selectionChange)="data.data[col.name+ 'Id'] = $event.value"
													[(ngModel)]="data.data[col.name + 'Id']">
													<mat-option *ngFor="let opt of col.options" [value]="opt._id">
														{{opt.name}} ({{opt.email}})</mat-option>
												</mat-select>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name+ 'Id'] === undefined || data.data[col.name+ 'Id'] === '' || data.data[col.name+ 'Id'].length === 0)">
													{{col.displayName| translate}} {{'is required' | translate}}
												</mat-error>
											</mat-form-field>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'lnglat'"
											style="width:100%;margin-bottom: 25px">
											<mat-label
												[class.mat-mdc-form-field-error]="isSubmitted && !col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">{{col.displayName}}<span
													*ngIf="!col.nullable">*</span></mat-label>
											<app-google-map #dialogMap [lazyLoad]="lazyLoadSafari" [height]="'250px'"
												(mapSelect)="markerMapSelect(col.name, $event)" [addMyMarker]="false"
												[setGeoLocation]="data.data[col.name]"
												(addressPinData)="addressPinData(col.name, $event)"
												(addressJPPinData)="addressJPPinData(col.name, $event)">
											</app-google-map>
											<mat-error class="mat-mdc-form-field-subscript-wrapper"
												*ngIf="isSubmitted && !col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} {{'is required' | translate}}
											</mat-error>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'picturearray'"
											class="picturearray" style="display: inline-block;">
											<label class="control-label" *ngIf="col.name">{{col.displayName}}<span
													*ngIf="!col.nullable"> *</span>:
												<mat-icon (click)="fileselect.click()" class="newRow" title="Add Image">
													add_circle_outline</mat-icon>
											</label>
											<input [hidden]="true" type="file" name="image" #fileselect multiple="true"
												accept="{{allowedAcceptExtensions}}"
												(change)="onBrowseFiles(col.name, $event.target)" />
											<div *ngIf="data.data[col.name]">
												<mat-card class="itemView"
													*ngFor="let itm of data.data[col.name]; let index=index">
													<mat-card-header>
														<div style="text-align: right;width: 100%;z-index: 1;">
															<em class="material-icons" style="cursor: pointer;"
																(click)="deletePictures(col.name, index)">delete_forever</em>
														</div>
													</mat-card-header>
													<div class="imageParent">
														<img mat-card-image src="{{itm.thumbnail}}"
															(error)="itm.thumbnail = itm.url"
															style="max-height: 190px;max-width: 180px;">
													</div>
													<mat-card-content class="itemData">
														<label class="control-label">Link To:</label>
														<mat-select ng-model="col.name"
															(selectionChange)="setPictureArrayType(index, col.name, $event.value)"
															[value]="data.data[col.name][index]['type']">
															<mat-option value="none">{{'None' | translate}}</mat-option>
															<mat-option value="link">{{'Url' | translate}}</mat-option>
															<mat-option value="tile">{{'Tile' | translate}}</mat-option>
															<mat-option value="room">{{'Room' | translate}}</mat-option>
															<mat-option value="page">{{'Page' | translate}}</mat-option>
														</mat-select>
														<!-- <mat-form-field class="example-full-width" *ngIf="data.data[col.name][index]['type'] !== 'none'">
														    <input name="link{{index}}" matInput ng-model="col.name"
																 [readonly]="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'" [(ngModel)]="data.data[col.name][index]['link']">
														    <mat-icon matSuffix *ngIf="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'" style="cursor:pointer" (click)="selectPictureLinkType(index, col.name, data.data[col.name][index]['type'])">mode_edit</mat-icon>
														  </mat-form-field> -->

														<mat-form-field type="url" style="width: 100%;"
															*ngIf="data.data[col.name][index]['type'] === 'link'">
															<input name="link{{index}}" matInput
																placeholder="{{ 'Link' | translate }}*"
																[(ngModel)]="data.data[col.name][index]['link']">
														</mat-form-field>
														<mat-form-field type="text" style="width: 100%;"
															*ngIf="data.data[col.name][index]['type'] === 'room' || data.data[col.name][index]['type'] === 'tile'">
															<input name="link{{index}}" matInput [readonly]="true"
																[(ngModel)]="data.data[col.name][index]['linkDisplay']"
																placeholder="{{ 'Select' | translate  }} {{data.data[col.name][index]['type']}}*">
															<mat-icon matSuffix style="cursor:pointer"
																(click)="selectPictureLinkType(index, col.name, data.data[col.name][index]['type'])">
																mode_edit</mat-icon>
														</mat-form-field>
														<mat-form-field class="example-full-width"
															*ngIf="data.data[col.name][index]['type'] === 'page'">
															<mat-label>{{'Select Page' | translate}}</mat-label>
															<mat-select name="link{{index}}"
																(selectionChange)="data.data[col.name][index]['link'] = $event.value"
																[value]="data.data[col.name][index]['link']">
																<ng-container *ngFor="let pg of pageList">
																	<mat-option *ngIf="!checkDisabled(pg.value)"
																		value="{{pg.value}}">{{pg.name | translate}}
																	</mat-option>
																</ng-container>
															</mat-select>
														</mat-form-field>


													</mat-card-content>
												</mat-card>
											</div>
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'datetime'">
											<app-custom-datetime [value]="data.data[col.name]" [metadata]="col"
												(dateChange)="setDateTimeAttribute(col.name, $event)"
												[disabled]="col.disabled"
												[disabledDate]="col.disabled || col.disabledDate"
												[disabledTime]="col.disabled || col.disabledTime"
												[hideDate]="col.hideDate">
											</app-custom-datetime>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'date'"
											class="dateClass">
											<mat-form-field *ngIf="col.name !== 'dob'">
												<mat-label>{{col.displayName | translate}}</mat-label>
												<input matInput name="{{col.name}}" [required]="!col.nullable"
													[(ngModel)]="data.data[col.name]" ng-model="col.name"
													[matDatepicker]="picker"
													placeholder="{{col.displayName| translate}}"
													(dateChange)="setDateAttribute(col.name, $event.value)"
													[errorStateMatcher]="esMatcher">
												<mat-datepicker-toggle matIconSuffix
													[for]="picker"></mat-datepicker-toggle>
												<mat-datepicker #picker></mat-datepicker>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName| translate}} {{'is required' | translate}}
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="col.name == 'dob'">
												<mat-label>{{col.displayName | translate}}</mat-label>
												<mat-select name="{{col.name}}" [required]="!col.nullable"
													[(ngModel)]="selectedYear[col.name]"
													placeholder="{{col.displayName| translate}}"
													(selectionChange)="setYearAttribute(col.name, $event.value)">
													<mat-option *ngFor="let year of years" [value]="year">
														{{ year }}
													</mat-option>
												</mat-select>
												<mat-icon matSuffix *ngIf="data.data[col.name] && !col.disabled"
													(click)="clearYearAttribute($event, col.name, '')"
													class="cancelIcon" matTooltip="{{'Clear' | translate}}"
													matTooltipClass="tooltip-red">close</mat-icon>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName| translate}} {{'is required' | translate}}
												</mat-error>
											</mat-form-field>
											<mat-form-field
												*ngIf="col.name == 'year-started' && data.dataType === 'user' && (metaFieldSetting['years_in_service'].visible || (metaFieldSetting['years_in_service'].admin && isAdmin) || (metaFieldSetting['years_in_service'].superadmin && isSuperAdmin))"
												class="inputClass">
												<mat-label>{{metaFieldSetting['years_in_service'].displayName |
													translate}}</mat-label>
												<input matInput type="number" [disabled]="true" name="years_in_service"
													ng-model="'years_in_service'" [value]="yearsInService"
													placeholder="{{metaFieldSetting['years_in_service']['displayName'] | translate}}">
											</mat-form-field>
											<!-- <mat-form-field
												*ngIf="col.name == 'dob' && data.dataType === 'user' && (metaFieldSetting['age'].visible || (metaFieldSetting['age'].admin && isAdmin) || (metaFieldSetting['age'].superadmin && isSuperAdmin))"
												class="inputClass">
												<input matInput type="number" [readonly]="true" name="age"
													ng-model="'age'" [value]="age"
													placeholder="{{metaFieldSetting['age']['displayName'] | translate}}">
												<mat-icon
													*ngIf="metaFieldSetting['age'].info && metaFieldSetting['age'].info !== ''"
													class="infoCss"
													title="{{metaFieldSetting['age'].info | translate}}">info</mat-icon>
											</mat-form-field> -->
										</div>
										<ng-container
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'enum'">
											<ng-container
												*ngIf="!col.multiple && (((data.dataType !== 'room') || ((data.dataType === 'room' && col.name !== 'priority' && col.name !== 'type') || (data.dataType === 'room' && col.name === 'priority' && data.data['type'] && data.data['type'] === '3'))))">
												<mat-form-field class="enumClass">
													<!-- <input [style.visibility]="'hidden'" matInput name="{{col.name}}"
														[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
														[errorStateMatcher]="esMatcher"> -->
													<mat-label>{{col.displayName | translate}}</mat-label>
													<mat-select name="{{col.name}}" [required]="!col.nullable"
														(selectionChange)="setAttribute(col.name, $event.value)"
														[(ngModel)]="data.data[col.name]" [disabled]="col.disabled">
														<!-- <mat-option value="" >Select {{col.displayName | translate}}</mat-option> -->
														<ng-container *ngFor="let itm of col.enum">
															<mat-option [value]="itm.value" *ngIf="!itm['hide']"
																[disabled]="col.enumDisabled && col.enumDisabled.includes(itm.value)">
																{{itm.displayName | translate}}</mat-option>
														</ng-container>
													</mat-select>
													<mat-icon matSuffix *ngIf="col.info && col.info !== ''"
														class="infoCss"
														matTooltip="{{col.info | translate}}">info</mat-icon>
													<mat-icon matSuffix *ngIf="data.data[col.name] && !col.disabled"
														(click)="clearSimpleValue($event, col.name, '')"
														class="cancelIcon"
														[class.cancelIconWithInfo]="col.info && col.info !== ''"
														matTooltip="{{'Clear' | translate}}"
														matTooltipClass="tooltip-red">close</mat-icon>
													<mat-error
														*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
														{{col.displayName | translate}} {{'is required' | translate}}
													</mat-error>
												</mat-form-field>
											</ng-container>
											<ng-container
												*ngIf="!col.multiple && (data.dataType === 'room' && col.name === 'type' && (!data.action || (data.action && (data.action === 'create' || data.action === 'edit'))) && data.data[col.name] !== '3')">
												<mat-form-field>
													<!-- <input [style.visibility]="'hidden'" matInput name="{{col.name}}"
														[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
														[errorStateMatcher]="esMatcher"> -->
													<mat-label>{{col.displayName | translate}}</mat-label>
													<mat-select name="{{col.name}}" [required]="!col.nullable"
														(selectionChange)="setAttribute(col.name, $event.value)"
														[(ngModel)]="data.data[col.name]" [disabled]="col.disabled">
														<mat-option value="">Select {{col.displayName | translate}}
														</mat-option>
														<ng-container *ngFor="let itm of col.enum">
															<mat-option *ngIf="itm.value !== '3'" [value]="itm.value">
																{{itm.displayName | translate}}</mat-option>
														</ng-container>
													</mat-select>
													<mat-error
														*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
														{{col.displayName | translate}} {{'is required' | translate}}
													</mat-error>
												</mat-form-field>
											</ng-container>
											<ng-container *ngIf="col.multiple">
												<mat-form-field>
													<!-- <input [style.visibility]="'hidden'" matInput name="{{col.name}}"
														[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
														[errorStateMatcher]="esMatcher"> -->
													<mat-label>{{col.displayName | translate}}</mat-label>
													<mat-select name="{{col.name}}" [required]="!col.nullable"
														(selectionChange)="setAttribute(col.name, $event.value)"
														[(ngModel)]="data.data[col.name]" [disabled]="col.disabled"
														multiple>
														<ng-container *ngFor="let itm of col.enum">
															<mat-option [value]="itm.value"
																[disabled]="col.enumDisabled && col.enumDisabled.includes(itm.value)">
																{{itm.displayName |
																translate}}</mat-option>
														</ng-container>
													</mat-select>
													<mat-error
														*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
														{{col.displayName | translate}} {{'is required' | translate}}
													</mat-error>
												</mat-form-field>
											</ng-container>
										</ng-container>
										<ng-container
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'gallery'"><mat-label>{{col.displayName
												| translate}}</mat-label>
											<mat-form-field class="inputClass" style="height: 95px;">

												<img *ngIf="!data.data[col.gallery.target]"
													style="height: 40px;width: 40px;visibility: hidden;"
													src="{{data.data[col.gallery.target]}}">
												<img *ngIf="data.data[col.gallery.target]"
													style="height: 40px;width: 40px;"
													src="{{data.data[col.gallery.target]}}">
												<input matInput [autofocus]="col.name===focusField"
													[required]="!col.nullable" name="{{col.name}}" ng-model="col.name"
													value="" [required]="!col.nullable" [errorStateMatcher]="esMatcher"
													[disabled]="true">

												<mat-icon matSuffix style="cursor: pointer;height: 57px;"
													matTooltip="{{'Image Gallery' | translate}}"
													(click)="openGallery(col.name, col)">photo_library</mat-icon>
											</mat-form-field>
										</ng-container>
									</span>
								</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div mat-dialog-actions>
			<!-- <span *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')"> -->
			<!-- <div class="sendEmail" *ngIf="!data.data._id && data.dataType === 'user'">
				<mat-checkbox name="sendEmail" [(ngModel)]="sendEmail"
					title="{{'Send registration email' | translate}}">
					{{'Send registration email' | translate}}
				</mat-checkbox>
			</div> -->
			<div class="sendEmail" *ngIf="!data.data._id && data.dataType === 'case'">
				{{'Note: Assigned investigators will be notified about this new case.' |
				translate}}
			</div>
			<div class="sendEmail" *ngIf="!data.data._id && data.dataType === 'user'">
				{{'Note: An email invitation will be sent to the officer to allow them to access the mobile app.' |
				translate}}
			</div>
			<button color="danger" [disabled]="(data.action && data.action === 'create-room-lobby')" mat-raised-button
				(click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')"
				[disabled]="loading">{{'Create' | translate}}</button>
			<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading">{{'Save' |
				translate}}</button>
		</div>
		<!-- <br /> -->
	</div>
</form>