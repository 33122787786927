<div class="mainTable">
  <ng-template [ngxPermissionsOnly]="permission">
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="canSelectFilter">
      <div fxFlex style="line-height: 75px;">
        <app-custom-select-autocomplete #customselectautocomplete *ngIf="selectedDatafilters" [value]="selectedDataName"
          [dataType]="dataTypeFilter" [dataTypeDisplay]="dataTypeDisplayFilter" [placeholder]="'Select Filter'"
          [filters]="selectedDatafilters" [canDelete]="false" (onSelectReturn)="setSelectedDataName($event)">
        </app-custom-select-autocomplete>
      </div>
      <div fxFlex>
      </div>
      <div fxFlex>
      </div>
    </div>
  </ng-template>
  <div class="mb-20" fxFlex fxLayout="row" fxLayout.lt-md="column">
    <div fxFlex>
      <div class="topLeftBtn" *ngIf="topLeftTemplate">
        <ng-container [ngTemplateOutlet]="topLeftTemplate">
        </ng-container>
      </div>
      <mat-form-field [hidden]="!(canSearch)">
        <input matInput #searchInput placeholder="{{ 'Search' | translate }}" [value]="searchVal" maxlength="100">
      </mat-form-field>
      <div class="topRightBtn" *ngIf="showTopRightBtn">
        <ng-template [ngxPermissionsOnly]="permission">
          <a [hidden]="!canFilter" href="Javascript:void(0)" *ngIf="customBubbleFilters.length > 0"
            (click)="clearFilterIt()">clear filter</a>
          <mat-icon (click)="filterIt()" [hidden]="!canFilter" class="filter" matTooltip="{{ 'Filter' | translate }}">
            filter_list</mat-icon>
          <mat-icon (click)="exportIt()" [hidden]="!canExport" class="filter" matTooltip="{{ 'Export' | translate }}">
            file_copy</mat-icon>
          <mat-icon (click)="importIt()" [hidden]="!canImport" class="filter"
            matTooltip="{{ 'Import CSV' | translate }}">
            upload_file</mat-icon>
        </ng-template>
        <mat-icon *ngIf="!canNewList" [hidden]="!(canNew && creatable)" (click)="create($event)" class="newRow"
          matTooltip="{{ 'Create New' | translate }}">add_circle_outline</mat-icon>
        <mat-icon *ngIf="canNewList" [hidden]="!(canNew && creatable)" (click)="triggerButtun($event, 'createClick')"
          class="newRow" matTooltip="{{ 'Create New' | translate }}">add_circle_outline</mat-icon>
        <button type="button" *ngIf="canNewList" mat-icon-button id="createClick"
          style="width: 0px;visibility: hidden;height: 0px;line-height: 0px" [matMenuTriggerFor]="canNewListoption"
          matTooltip="{{ 'Create New' | translate }}" matTooltipClass="tooltip-red">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <mat-menu #canNewListoption="matMenu">
          <button type="button" mat-menu-item *ngFor="let clk of canNewList"
            (click)="create($event, clk.value, clk.typeName)" [disabled]="clk.disabled"><span
              style="pointer-events: all !important;" matTooltipClass="tooltip-red"
              matTooltip="{{clk.disabled ? clk.disabledLabel : ''}}">{{clk.displayName |
              translate}}</span></button>
        </mat-menu>
        <mat-icon (click)="refresh()" [hidden]="!canRefresh" class="refresh"
          matTooltip="{{ 'Refresh' | translate }}">refresh
        </mat-icon>
      </div>
      <div class="topRightBubbles" *ngIf="metaFieldSetting && canFilter">
        <mat-chip-listbox>
          <mat-chip-option *ngFor="let bb of customBubbleFilters">
            <span *ngIf="!bb.parent">{{metaFieldSetting[bb.displayName].displayName}}</span>
            <span *ngIf="bb.parent">{{metaFieldSetting[bb.parent][bb.displayName].displayName}}</span>

            <mat-icon style="cursor:pointer;margin-left: 3px;" (click)="removeFilter(bb.name)">{{'Close' | translate}}
            </mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div #myIdentifier class="example-table-container mat-elevation-z8" style="clear: both;"
        [class.example-container-preview]="isPreview">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" [matSortActive]="orderBy"
          [matSortDisableClear]="true" [matSortDirection]="orderDir" class="myTable"
          [class.big-table]="displayedColumns.length > 10" [style.max-width.px]="innerWidth">
          <!-- ID Column-->
          <ng-container *ngIf="tableSetting">
            <ng-container matColumnDef="{{col.parent === '' ? col.name : col.parent + '.' + col.name}}"
              *ngFor="let col of tableSetting.fields" [stickyEnd]="col.type === 'action'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!col.orderable || !canSort">
                <span
                  *ngIf="col.name !== 'pictureLink' && col.name !== 'picture' && col.name !== 'selection'">{{col.displayName
                  |
                  translate}}</span>
                <mat-checkbox *ngIf="col.name === 'selection'" (change)="$event ? toggleAllRows() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let objectData; let index = index">
                <span *ngIf="col.type !== 'action'">
                  <span *ngIf="col.parent === ''">
                    <span *ngIf="col.type === 'image' " matTooltip="{{objectData[col.name]}}">
                      <span class="profile-page-header">
                        <figure class="card-profile-image">
                          <div class="circle-image">
                            <img *ngIf="objectData[col.name] && objectData[col.name] !== ''"
                              style="max-height: 40px;max-width: 40px;margin-top: 5px;" src="{{objectData[col.name]}}"
                              class="circle z-depth-2 responsive-img activator">
                            <img
                              *ngIf="col.name === 'pictureLink' && !(objectData[col.name] && objectData[col.name] !== '')"
                              style="max-height: 40px;max-width: 40px;margin-top: 5px;" src="{{defaultPictureLink}}"
                              class="circle z-depth-2 responsive-img activator">
                          </div>
                        </figure>
                      </span>
                    </span>
                    <span *ngIf="objectData[col.name] || objectData[col.name] === 0">
                      <span *ngIf="normalDisplay.includes(col.type)" matTooltip="{{objectData[col.name]}}">
                        <!-- <span *ngIf="col.name === 'name' && objectData.hasOwnProperty('active')"
                          title="{{objectData[col.name]}}">
                          <mat-icon *ngIf="objectData['active']" class="activeColor">brightness_1</mat-icon>
                          <mat-icon *ngIf="!objectData['active']" class="inactiveColor">brightness_1</mat-icon>
                        </span> -->
                        <ng-container *ngIf="linkableView">
                          <a class="linkableView" (click)="toggleClick('view', 'parent', objectData, index)">
                            <ng-container *ngIf="col.name === 'name' && dataType === 'user'">
                              {{objectData['alternative_name'] |
                              truncate : ['50','...']}}
                            </ng-container>
                            <ng-container *ngIf="!(col.name === 'name' && dataType === 'user')">
                              {{objectData[col.name] |
                              truncate : ['50','...']}}
                            </ng-container>

                          </a>
                        </ng-container>
                        <ng-container *ngIf="!linkableView">
                          {{objectData[col.name] | truncate : ['50','...']}}
                        </ng-container>
                      </span>
                      <span *ngIf="col.type === 'textarea'" matTooltip="{{objectData[col.name]}}">
                        {{objectData[col.name] | truncate : ['50','...']}}
                      </span>
                      <span *ngIf="col.type === 'datetime'">
                        {{objectData[col.name] | date: 'yyyy-MM-dd HH:mm' }}
                      </span>
                      <span *ngIf="col.type === 'boolean'">
                        <span *ngIf="objectData[col.name]">True</span>
                        <span *ngIf="!objectData[col.name]">False</span>
                      </span>
                      <span *ngIf="col.type === 'reference'">
                        <span *ngIf="col.reference.kind === 'single'">{{objectData[col.name]['name']}} <span
                            *ngIf="col.name === 'program' && objectData['phd']"> PhD</span></span>
                        <span *ngIf="col.reference.kind === 'multiple' && col.name !== 'resources'">
                          <span *ngFor="let fld of objectData[col.name];let subindex = index">{{fld.name}}<span
                              *ngIf="subindex < objectData[col.name].length - 1">, </span></span>
                        </span>
                        <span *ngIf="col.reference.kind === 'multiple' && col.name === 'resources'">
                          <!-- <span *ngIf="objectData['isSuperAdmin']">
                            Admin
                          </span>
                          <span
                            *ngIf="!objectData['isSuperAdmin'] && objectData[col.name].length > 0"> -->
                          {{objectData[col.name][0].name}}
                          <!-- </span> -->
                          <!-- <span *ngFor="let fld of objectData[col.name];let subindex = index">{{fld.name }}<span *ngIf="subindex < objectData[col.name].length - 1">, </span></span> -->
                        </span>
                      </span>
                      <span *ngIf="col.type === 'arrayreference'">
                        <span *ngFor="let fld of objectData[col.name];let subindex = index">{{fld.name}}<span
                            *ngIf="subindex < objectData[col.name].length - 1">, </span></span>
                      </span>
                      <span *ngIf="col.type === 'objectjson' && col.hasOwnProperty('isHost')">
                        <span *ngFor="let fld of objectData[col.name];let subindex = index"><span
                            *ngIf="col.isHost === fld.host">{{fld.name}}</span></span>
                      </span>
                      <span *ngIf="col.type === 'enum'">
                        <app-enum-view [enumValue]="objectData[col.name]" [enumList]="col.enum"></app-enum-view>
                      </span>
                      <span *ngIf="col.type === 'tags'">
                        <mat-chip-listbox aria-label="Tags">
                          <mat-chip-option *ngFor="let tg of objectData[col.name]">{{tg.name}}</mat-chip-option>
                        </mat-chip-listbox>
                      </span>
                    </span>
                  </span>
                  <span *ngIf="col.parent !== ''">
                    <span *ngIf="objectData[col.parent] && objectData[col.parent][col.name]">
                      <span *ngIf="normalDisplay.includes(col.type)" matTooltip="{{objectData[col.parent][col.name]}}">
                        {{objectData[col.parent][col.name] | truncate : ['50','...']}}
                      </span>
                      <span *ngIf="col.type === 'textarea'" matTooltip="{{objectData[col.parent][col.name]}}">
                        {{objectData[col.parent][col.name] | truncate : ['50','...']}}
                      </span>
                      <span *ngIf="col.type === 'datetime'">
                        {{objectData[col.parent][col.name] | date: 'mediumDate'}}
                      </span>
                      <span *ngIf="col.type === 'boolean'">
                        <span *ngIf="objectData[col.parent][col.name]">True</span>
                        <span *ngIf="!objectData[col.parent][col.name]">False</span>
                      </span>
                      <span *ngIf="col.type === 'reference'">
                        <span
                          *ngIf="col.reference.kind === 'single'">{{objectData[col.parent][col.name]['name']}}</span>
                        <span *ngIf="col.reference.kind === 'multiple'">
                          <span
                            *ngFor="let fld of objectData[col.parent][col.name];let subindex = index">{{fld.name}}<span
                              *ngIf="subindex < objectData[col.parent][col.name].length - 1">, </span></span>
                        </span>
                      </span>
                      <span *ngIf="col.type === 'arrayreference'">
                        <span
                          *ngFor="let fld of objectData[col.parent][col.name];let subindex = index">{{fld.name}}<span
                            *ngIf="subindex < objectData[col.parent][col.name].length - 1">, </span></span>
                      </span>
                      <span *ngIf="col.type === 'objectjson' && col.hasOwnProperty('isHost')">
                        <span *ngFor="let fld of objectData[col.parent][col.name];let subindex = index"><span
                            *ngIf="col.isHost === fld.host">{{fld.name}}</span></span>
                      </span>
                      <span *ngIf="col.type === 'enum'">
                        <app-enum-view [enumValue]="objectData[col.parent][col.name]" [enumList]="col.enum">
                        </app-enum-view>
                      </span>
                      <span *ngIf="col.type === 'tags'">
                        <mat-chip-listbox aria-label="Tags">
                          <mat-chip-option *ngFor="let tg of objectData[col.parent][col.name]">{{tg.name}}
                          </mat-chip-option>
                        </mat-chip-listbox>
                      </span>
                    </span>
                  </span>
                </span>
                <span *ngIf="col.type === 'action'">
                  <span *ngIf="col.dataType === 'array'">
                    <span *ngFor="let act of col.actions">
                      <span *ngIf="act.hasOwnProperty('rule')">
                        <span *ngIf="(objectData[act.rule[0]] == act.rule[1] || act.isAdminRule && isAdmin)">
                          <ng-container
                            *ngIf="(!act.hasOwnProperty('disableRule')) || (act.hasOwnProperty('disableRule') && objectData[act.disableRule[0]] !== act.disableRule[1])">
                            <button type="button"
                              (click)="toggleClick(act.action, act.target, objectData, index, act?.checkusability)"
                              mat-icon-button color="primary" matTooltip="{{act.displayName | translate}}">
                              <mat-icon>{{act.icon}}</mat-icon>
                            </button>
                          </ng-container>
                          <ng-container
                            *ngIf="act.hasOwnProperty('disableRule') &&  objectData[act.disableRule[0]] === act.disableRule[1]">
                            <button type="button" mat-icon-button color="primary" class="disabledMenuIcon"
                              matTooltip="{{act.disableRule[2] | translate}}">
                              <mat-icon>{{act.icon}}</mat-icon>
                            </button>
                          </ng-container>
                        </span>
                      </span>
                      <span *ngIf="!act.hasOwnProperty('rule')">
                        <mat-icon class="mat-icon-alone"
                          (click)="toggleClick(act.action, act.target, objectData, index, act?.checkusability)"
                          matTooltip="{{act.displayName | translate}}">{{act.icon}}</mat-icon>
                        <!-- <button type="button"
                          (click)="toggleClick(act.action, act.target, objectData, index, act?.checkusability)"
                          mat-icon-button color="primary" title="{{act.displayName | translate}}">
                          <mat-icon>{{act.icon}}</mat-icon>
                        </button> -->
                      </span>
                    </span>
                  </span>
                  <span *ngIf="col.dataType === 'select'">
                    <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(objectData) : null"
                      [checked]="selection.isSelected(objectData)" [aria-label]="checkboxLabel(objectData)">
                    </mat-checkbox>
                  </span>
                  <!-- <span *ngIf="col.dataType !== 'array'">
                    <button type="button" *ngIf="editable && col.dataType !== 'button' && col.action === 'edit'"
                      (click)="toggleClick(col.action, col.target, objectData, index)" mat-icon-button color="primary"
                      matTooltip="{{'Edit' | translate}}">
                      <mat-icon>create</mat-icon>
                    </button>
                    <button type="button" *ngIf="col.dataType !== 'button' && col.action === 'view'"
                      (click)="toggleClick(col.action, col.target, objectData, index)" mat-icon-button color="primary"
                      matTooltip="{{'Edit' | translate}}">
                      <mat-icon>open_in_browser</mat-icon>
                    </button>
                    <button type="button" *ngIf="deletable && col.dataType !== 'button' && col.action === 'delete'"
                      mat-icon-button color="primary" matTooltip="{{'Delete' | translate}}" type="button"
                      (click)="toggleClick(col.action, col.target, objectData, index)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </span> -->
                </span>
              </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-progress-spinner [diameter]="30" *ngIf="loading" class="mat-spinner-color" color="warn"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <div class="mat-table__message"><span *ngIf="firstLoad && !loading && tableDataTotal === 0">{{ 'No records found'
          | translate
          }}</span></div>
      <mat-paginator class="paginator" [pageIndex]="pageNumber-1" [hidden]="!pagination" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]" [length]="paginatorTotal | async" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
    <!-- end::Body -->
  </div>
</div>