import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../shared/services';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public showLoader: boolean = false;
  public color: string = '';

  constructor(private loaderService: LoaderService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscriptions.push(
      this.loaderService.status.subscribe((val: boolean) => {
        this.showLoader = val;
        this.color = '';
        this.changeDetector.detectChanges();
      })
    );

    this.subscriptions.push(
      this.loaderService.statusWithColor.subscribe((val: any) => {
        if (val) {
          this.showLoader = val.display;
          this.color = val.color;
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
