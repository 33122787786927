import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LayoutUtilsService, LoaderService, RequestService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { GoogleMapsComponent } from '../google-map/google-map.component';
import { environment } from 'src/environments/environment';
import { prepareInvestigator } from '../google-map/data-prepare';



interface DialogData {
  targetDataType: string;
  targetDataTypeDisplay: string;
  dataType: string;
  title: string;
  data: any;
  filters: any;
  filtersGroup: any;
  caseId: string;
  caseName: string;
  caseData: string;
  type: string;
  limit: number;
  limitMessage: string;
  columnData: string;
  confirmData: any;
}


@Component({
  selector: 'investigators-assign-dialog',
  templateUrl: './investigators-assign-dialog.component.html',
  styleUrls: ['./investigators-assign-dialog.component.scss']
})
export class InvestigatorsAssignDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public apiCallSubscription: any = undefined;
  public selectedUser: any;
  public errorMessage: string = '';
  public loading: boolean = false;
  public loadingGroup: boolean = false;
  public hasFormErrors: boolean = false;
  public pagination: boolean = true;
  public paginationGroup: boolean = true;
  public canSearch: boolean = true;
  public optionData: any = [];
  public optionGroupData: any = [];
  public selectedData: any = [];
  public selectedGroupData: any = [];
  public searchVal: string = '';
  public tableDataTotal: number = 0;
  public tableDataTotalGroup: number = 0;
  public showMap = false;
  public tabMode = 'list';

  public baseImage = '';
  public baseImageSet = {};
  public baseImageSetDegrees = [0, 45, 90, 135, 180, 225, 270, 315];
  public centerLngLat: any = { lng: '', lat: '' };
  orderByList = [{ field: 'name', order: 'asc' }];

  /* pagination Info */
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public paginatorTotalGroup: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageSize = 5;
  pageSizeGroup = 5;
  pageNumber = 1;
  pageNumberGroup = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('paginatorsingle') paginator: MatPaginator;
  @ViewChild('paginatorgroup') paginatorGroup: MatPaginator;

  @ViewChild('currentDialogMap') currentMap: GoogleMapsComponent;
  constructor(
    private requestService: RequestService, private loaderService: LoaderService, private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    public dialogRef: MatDialogRef<InvestigatorsAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);
  }

  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.paginator.pageIndex = 0;
            this.paginatorGroup.pageIndex = 0;
            this.pageNumber = 1;
            this.pageNumberGroup = 1;
            this.loadDataSearch();
            this.loadDataGroupSearch();
          }
        } catch (e) { }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
    const paginatorSubscriptionsGroup = merge(this.paginatorGroup.page).pipe(
      tap(() => {
        this.getTableVariablesGroup();
        this.loadDataGroupSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptionsGroup);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
        }
      })
    );
  }
  getInvestigatorsList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getInvestigatorsList(this.data.caseId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          // console.log('getClientsList', data.results);
          this.selectedData = data.results.map((itm) => {
            itm['selected'] = true;
            itm['isInvestigatorGroup'] = false;
            return itm;
          });
        } else {
          this.selectedData = [];
        }
        this.loading = false;
        this.getInvestigatorGroupList();
      });
    }
  }
  getInvestigatorGroupList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getInvestigatorGroupList(this.data.caseId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          // console.log('getClientsList', data.results);
          let selectedData = data.results.map((itm) => {
            itm['selected'] = true;
            itm['isInvestigatorGroup'] = true;
            return itm;
          });
          this.selectedData = this.selectedData.concat(selectedData);
        } else {
          // this.selectedData = [];
        }
        this.loading = false;
        this.loadDataSearch(() => {
          this.loadDataGroupSearch(() => {
            this.loaderService.display(false);
          });
        });
      });
    }
  }
  public getTableVariables() {
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  public getTableVariablesGroup() {
    this.pageNumberGroup = this.paginatorGroup.pageIndex + 1;
    this.pageSizeGroup = this.paginatorGroup.pageSize;
  }
  public loadDataSearch(callback = undefined) {
    if (this.loading && this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let fieldsConfiguration = ["name", "alternative_name", "region", "prefecture", "geolocation", "pictureLink", "email"];
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }
      let excludeList = [];
      if (this.selectedData.length > 0) {
        excludeList = this.selectedData.map(itm => itm._id)
      }

      // for (let dt of this.selectedData) {
      //   excludeList.push(dt._id);
      // }
      this.apiCallSubscription = this.requestService.getDataList(this.data.targetDataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSize, filter: filterConfiguration, fieldKeys: fieldsConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any = data;
          //console.log('dataReturned', dataReturned);
          if (dataReturned.pagination) {
            this.tableDataTotal = dataReturned.pagination.total;
            this.paginatorTotal.next(dataReturned.pagination.total);
          }
          this.optionData = dataReturned.results.map((itm) => {
            itm['selected'] = false;
            itm['isInvestigatorGroup'] = false;
            return itm;
          });
        } else {
          this.optionData = [];
        }
        this.loading = false;
        if (callback) {
          callback()
        }
      });
    }
  }
  public loadDataGroupSearch(callback = undefined) {
    if (this.loadingGroup && this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.loadingGroup = false;
    }
    if (!this.loadingGroup) {
      this.loadingGroup = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let fieldsConfiguration = ["name", "region", "prefecture", "investigators"];
      let filterConfiguration = undefined;
      if (this.data.filtersGroup) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filtersGroup));
      }
      let excludeList = [];
      if (this.selectedData.length > 0) {
        excludeList = this.selectedData.filter(itm => itm.isInvestigatorGroup);
        excludeList = this.selectedData.map(itm => itm._id);
      }

      // for (let dt of this.selectedData) {
      //   excludeList.push(dt._id);
      // }
      this.apiCallSubscription = this.requestService.getDataList('investigatorgroup', { page: this.pageNumberGroup, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSizeGroup, filter: filterConfiguration, fieldKeys: fieldsConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any = data;
          //console.log('dataReturned', dataReturned);
          if (dataReturned.pagination) {
            this.tableDataTotalGroup = dataReturned.pagination.total;
            this.paginatorTotalGroup.next(dataReturned.pagination.total);
          }
          this.optionGroupData = dataReturned.results.map((itm) => {
            itm['selected'] = false;
            itm['isInvestigatorGroup'] = true;
            return itm;
          });
        } else {
          this.optionGroupData = [];
        }
        this.loadingGroup = false;
        if (callback) {
          callback()
        }
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  openMap(status): void {
    this.showMap = status;
    if (status) {
      this.tabMode = 'map';
      this.setUpMarker();
    } else {
      this.tabMode = 'list';
      this.loadDataSearch();
    }
  }
  changeMode(mode): void {
    console.log('changeMode', mode);
  }
  selectData(): void {
    if (this.data.limit) {
      let selectedData = this.selectedData.filter(itm => !itm.isInvestigatorGroup);
      let selectedDataGroup = this.selectedData.filter(itm => itm.isInvestigatorGroup);
      if (selectedData.length > this.data.limit) {
        if (this.data.limitMessage) {
          this.layoutUtilsService.showNotification(this.data.limitMessage, 'Dismiss');
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You cannot select more than') + ' ' + this.data.limit + ' ' + this.data.targetDataTypeDisplay, 'Dismiss');
        }
      } else if (selectedDataGroup.length > this.data.limit) {
        if (this.data.limitMessage) {
          this.layoutUtilsService.showNotification(this.data.limitMessage, 'Dismiss');
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You cannot select more than') + ' ' + this.data.limit + ' ' + 'investigator group', 'Dismiss');
        }
      } else {
        this.continueAssign();
      }
    } else {
      this.continueAssign();
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.previousContainer.connectedTo instanceof Array && event.previousContainer.connectedTo.length === 2) {
        let currentIndex = event.currentIndex;
        if (event.previousContainer.data[event.previousIndex].isInvestigatorGroup) {
          if (typeof event.previousContainer.connectedTo[1] === 'string') {
            // Handle string case
          } else {
            if (event.previousContainer.connectedTo[1].data.length < currentIndex) {
              currentIndex = 0;
            }
            transferArrayItem(event.previousContainer.data,
              event.previousContainer.connectedTo[1].data,
              event.previousIndex,
              currentIndex);
            this.optionGroupData = this.optionGroupData.map((itm) => {
              itm['selected'] = false;
              return itm;
            });
            this.tabMode = 'invgroup';
          }
        } else {
          if (typeof event.previousContainer.connectedTo[0] === 'string') {
            // Handle string case
          } else {
            if (event.previousContainer.connectedTo[0].data.length < currentIndex) {
              currentIndex = 0;
            }
            transferArrayItem(event.previousContainer.data,
              event.previousContainer.connectedTo[0].data,
              event.previousIndex,
              currentIndex);
            this.optionData = this.optionData.map((itm) => {
              itm['selected'] = false;
              return itm;
            });
            this.tabMode = 'list';
          }
        }
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);

        this.selectedData = this.selectedData.map((itm) => {
          itm['selected'] = true;
          return itm;
        });
      }
    }
  }
  /** FILTRATION */
  termConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  continueAssign() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.requestService.assignInvestigators(this.data.caseId, this.selectedData, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        this.loaderService.display(false);
        if (data) {
          this.layoutUtilsService.showNotification(this.data.targetDataTypeDisplay + ' ' + this.translate.instant('assigned successfully'), this.translate.instant('Dismiss'));
          this.dialogRef.close(this.selectedData);
        }
      });
    }
  }
  addMarkers(dataResultUsers = []) {
    let allMarkers = [];
    // dataResultUsers = dataResultUsers.filter(itm => itm.geolocation);
    if (dataResultUsers.length > 0) {
      dataResultUsers = dataResultUsers.map((itm) => {
        return prepareInvestigator(itm, undefined, true);
      });
      // console.log('dataResultUsers', dataResultUsers);
      allMarkers = allMarkers.concat(dataResultUsers);
    }
    this.currentMap.addMarkers(allMarkers);
  }
  getRandomElement(numbers) {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    return numbers[randomIndex];
  }
  showGeoLocation(event) {
    this.loaderService.display(true);
    this.centerLngLat = event;
    this.getInvestigatorsList();
  }
  setUpMarker(callback = undefined) {
    let allMarkers = [];
    allMarkers = allMarkers.concat(this.selectedData);
    this.loadInvestigatorData((dataResultInvestigator) => {
      allMarkers = allMarkers.concat(dataResultInvestigator);
      this.addMarkers(allMarkers);
      if (callback) {
        callback();
      }
    });
  }
  markerSelect(mark: any) {
    // console.log('markerSelect', mark);
    if (this.selectedData.find(i => i._id === mark._id)) {
      this.selectedData = this.selectedData.filter(i => i._id !== mark._id);
    } else {
      mark['selected'] = true;
      this.selectedData.push(mark);
    }
    this.setUpMarker();
  }
  infoSelect(mark: any) {
    // console.log('infoSelect', mark);
    if (this.selectedData.find(i => i._id === mark._id)) {
      this.selectedData = this.selectedData.filter(i => i._id !== mark._id);
    } else {
      mark['selected'] = true;
      this.selectedData.push(mark);
    }
    this.setUpMarker();

  }
  clearData(): void {
    this.selectedData = [];
    this.setUpMarker(() => {
      this.loadDataSearch();
      this.loadDataGroupSearch();
    });
  }
  loadInvestigatorData(callback) {
    let dataResultInvestigator = [];
    if (this.loading && this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      let fieldsConfiguration = ["name", "alternative_name", "region", "prefecture", "geolocation", "pictureLink", "email", "years_in_service", "rank"];
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }
      let excludeList = [];
      if (this.selectedData.length > 0) {
        excludeList = this.selectedData.map(itm => itm._id)
      }
      // we might need to get the camera near this case (this.selectedMarker)

      let filterObj = { page: 1, perpage: 1000000, orderDir: this.orderDir, orderBy: this.orderBy, orderByList: this.orderByList, filter: filterConfiguration, fieldKeys: fieldsConfiguration, exclude: excludeList };
      this.apiCallSubscription = this.requestService.getDataList('user', filterObj, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let dataResult = data.results.map((itm) => {
            itm['selected'] = false;
            return itm;
          });
          dataResultInvestigator = JSON.parse(JSON.stringify(dataResult));

        }
        this.loading = false;
        if (callback) {
          callback(dataResultInvestigator)
        }
      });
    }
  }
}
