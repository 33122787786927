<div class="col-xl-12">
	<h1 mat-dialog-title>{{data.title | translate}}</h1>
	<div mat-dialog-content>
		<div class="userInfo select-page-body">
			<mat-card>
				<!-- <mat-card-header>
					<mat-card-title>{{data.type}}</mat-card-title>
				</mat-card-header> -->
				<mat-card-content>
					<div fxLayout="row" fxLayout.lt-md="column" fxFlex>
						<div fxFlex="80" *ngIf="tabMode === 'list'" style="font-size: 1rem;">
							{{'Drag investigator names to assign them to the investigation case' | translate}}
						</div>
						<div fxFlex="80" *ngIf="tabMode === 'map'" style="font-size: 1rem;">
							{{'Select investigator to assign them to the investigation case' | translate}}
						</div>
						<div fxFlex="80" *ngIf="tabMode === 'invgroup'" style="font-size: 1rem;">
							{{'Drag investigator group names to assign them to the investigation case' | translate}}
						</div>
						<div fxFlex="20" [align]="'right'" style="padding-bottom:3px">
							<button mat-icon-button color="secondary" *ngIf="tabMode === 'map'"
								(click)="openMap(false)">
								<mat-icon>list</mat-icon>
							</button>
							<button mat-icon-button color="secondary" *ngIf="tabMode === 'list'"
								(click)="openMap(true)">
								<mat-icon>map</mat-icon>
							</button>
							<button mat-icon-button color="secondary" *ngIf="tabMode === 'invgroup'"
								style="visibility: hidden;">
								<mat-icon>list</mat-icon>
							</button>
						</div>
					</div>
					<!-- <div [hidden]="!showMap" fxLayout="row" fxLayout.lt-md="column" fxFlex>
						<div fxFlex>

						</div>
					</div> -->
					<div fxLayout="row" fxLayout.lt-md="column" fxFlex>
						<div fxFlex class="bodybg" [hidden]="!showMap">
							<app-google-map #currentDialogMap [height]="'350px'" [addMyMarker]="false"
								(geoLocation)="showGeoLocation($event)" [isHoverable]="true"
								(markerSelect)="markerSelect($event)" (markerInfoSelect)="infoSelect($event)">
							</app-google-map>
						</div>
						<div fxFlex class="bodybg" [hidden]="showMap">
							<div fxLayout="row" fxLayout.lt-sm="column" fxFlex>
								<div fxFlex>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex style="height: 75px;">
										<div fxFlex>
											<!-- <div style="float:right">
												<mat-form-field [hidden]="!(canSearch)">
													<input matInput #searchInput placeholder="{{'Search' | translate}}"
														[value]="searchVal" maxlength="100">
												</mat-form-field>
											</div> -->
											<h2 style="margin:0px 0px 2px;width: fit-content;">
												{{data.targetDataTypeDisplay | translate}}
												{{'List' | translate}}</h2>
											<mat-button-toggle-group name="fontStyle" aria-label="Font Style"
												[(ngModel)]="tabMode" (change)="changeMode($event.value)">
												<mat-button-toggle value="list">{{'Individual' |
													translate}}</mat-button-toggle>
												<mat-button-toggle value="invgroup">{{'Group' |
													translate}}</mat-button-toggle>
											</mat-button-toggle-group>
										</div>
										<div fxFlex [align]="'right'">
											<mat-form-field [hidden]="!(canSearch)" style="width: 100%;">
												<input matInput #searchInput placeholder="{{'Search' | translate}}"
													[value]="searchVal" maxlength="100">
											</mat-form-field>
										</div>
									</div>
									<!-- <div fxLayout="row" fxLayout.lt-md="column" fxFlex>
										<div fxFlex>
											<mat-form-field [hidden]="!(canSearch)">
												<input matInput #searchInput placeholder="{{'Search' | translate}}"
													[value]="searchVal">
											</mat-form-field>
										</div>
									</div> -->
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex [hidden]="tabMode !== 'list'">
										<div fxFlex class="optionData.length > 0">
											<div cdkDropList #todoList="cdkDropList" [cdkDropListData]="optionData"
												[cdkDropListConnectedTo]="[doneList]" class="example-list"
												(cdkDropListDropped)="drop($event)">
												<div style="min-height:200px">
													<div class="example-box" *ngFor="let usr of optionData" cdkDrag>
														{{usr.alternative_name
														?
														usr.alternative_name: usr.name}}<span *ngIf="usr.email">
															({{usr.email}})</span>
													</div>
												</div>

												<mat-paginator #paginatorsingle class="paginator"
													[pageIndex]="pageNumber-1" [hidden]="!pagination"
													[pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25]"
													[length]="paginatorTotal | async"
													[showFirstLastButtons]="true"></mat-paginator>
											</div>
										</div>
									</div>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex
										[hidden]="tabMode !== 'invgroup'">
										<div fxFlex>
											<div cdkDropList #todoListGroup="cdkDropList"
												[cdkDropListData]="optionGroupData"
												[cdkDropListConnectedTo]="[doneList]" class="example-list"
												(cdkDropListDropped)="drop($event)">
												<div style="min-height:200px">
													<div class="example-box" *ngFor="let usr of optionGroupData"
														cdkDrag>
														{{usr.alternative_name
														?
														usr.alternative_name: usr.name}}
														<span
															*ngIf="usr.isInvestigatorGroup && usr.investigators && usr.investigators.length > 0">(<span
																*ngFor="let itm of usr.investigators;let cIndex = index">{{itm.alternative_name
																?
																itm.alternative_name: itm.name}}<span
																	*ngIf="cIndex < usr.investigators.length -1">,
																</span></span>)</span>
													</div>
												</div>

												<mat-paginator #paginatorgroup class="paginator"
													[pageIndex]="pageNumberGroup-1" [hidden]="!paginationGroup"
													[pageSize]="pageSizeGroup" [pageSizeOptions]=" [5, 10, 25]"
													[length]="paginatorTotalGroup | async"
													[showFirstLastButtons]="true"></mat-paginator>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div fxFlex class="bodybg">
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex style="height: 42px;">
								<div fxFlex>
									<div style="float:right">
										<button mat-button color="secondary" (click)="clearData()">
											<mat-icon>update</mat-icon> {{'Clear Selection' | translate}}
										</button>
									</div>
									<h2 style="margin:0px 0px 10px;width: fit-content;">
										{{'Selected' | translate}} {{data.targetDataTypeDisplay | translate}}</h2>
								</div>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex>
								<div fxFlex class="theDataSelected">
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex>
										<div fxFlex>
											<div cdkDropList #doneList="cdkDropList" [cdkDropListData]="selectedData"
												[cdkDropListDisabled]="showMap"
												[cdkDropListConnectedTo]="[todoList, todoListGroup]"
												class="example-list" (cdkDropListDropped)="drop($event)">
												<div class="example-box" [class.example-box-disabled]="showMap"
													*ngFor="let usr of selectedData;let index = index" cdkDrag>
													{{usr.alternative_name
													?
													usr.alternative_name: usr.name}} <span
														*ngIf="!usr.isInvestigatorGroup">({{usr.email}})</span>
													<span
														*ngIf="usr.isInvestigatorGroup && usr.investigators && usr.investigators.length > 0">(<span
															*ngFor="let itm of usr.investigators;let cIndex = index">{{itm.alternative_name
															?
															itm.alternative_name: itm.name}}<span
																*ngIf="cIndex < usr.investigators.length -1">,
															</span></span>)</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal()">{{'Close' | translate}}</button>
		<button mat-raised-button [disabled]="loading" (click)="selectData()">{{'Save' | translate}}</button>
	</div>
	<!-- <br /> -->
</div>