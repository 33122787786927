import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import moment from "moment";
import { ModalDialogComponent } from '../custom-dialog/custom-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-cases-list',
  templateUrl: './custom-cases-list.component.html',
  styleUrls: ['./custom-cases-list.component.scss']
})
export class CustomCasesListComponent implements OnInit {
  public enumListObj: any = {};
  groupedData: { [key: string]: any[] } = {};
  public _casesList: any = undefined;
  @Input() locale: any = 'en';
  @Input() metaFieldSetting: any = undefined;
  @Input() tableSetting: any = undefined;
  @Input() isInvestigator: boolean = false;
  public dataType: string = 'case';
  public dataTypeMultipleDisplay: string = 'Cases';
  public dataTypeDisplay: string = 'Case';
  public dataTypeSingleDisplay: string = 'Case'
  @Input()
  set casesList(casesList: any[]) {
    this._casesList = casesList;
    this.prepareCasesView(casesList);
  }
  get casesList(): any[] {
    return this._casesList;
  }
  @Output() onClickBtn = new EventEmitter<any>();
  constructor(public dialog: MatDialog, private translate: TranslateService, private router: Router) {
  }
  ngOnInit() { }
  prepareCasesView(dataList) {
    if (dataList && dataList.length > 0) {
      this.groupedData = dataList.reduce((groups, item) => {
        const date = moment(item.createdAt).format('YYYY/MM/DD');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
    } else {
      this.groupedData = {};
    }

  }
  objectKeys = Object.keys;

  public edit(item, index) {
    if (!this.isInvestigator) {
      let dataTypeSingleDisplay = this.dataTypeSingleDisplay;
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '640px',
        data: {
          dataType: this.dataType,
          dataTypeTitle: this.dataTypeDisplay,
          dataTypeSingleTitle: this.dataTypeSingleDisplay,
          title: this.translate.instant('Edit') + ' ' + this.translate.instant(dataTypeSingleDisplay),
          data: { _id: item._id },
          action: 'edit',
          modalSetting: JSON.parse(JSON.stringify(this.tableSetting)),
          focusField: 'name'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onClickBtn.emit({ action: 'refresh', data: result, index: index });
        }
      });
    }
  }

  openCase(id) {
    this.router.navigate(['/case', id]);
  }
  openCaseMap(id) {
    this.router.navigate(['/case/' + id + '/map']);
  }
}
